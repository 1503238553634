/* global axios */
export default class ACLScopeService {
  constructor() {
    this.axios = axios;
  }

  all() {
    return this.axios.get('acl/scopes');
  }

  store(scope) {
    return this.axios.post('acl/scope', { scope });
  }

  update(scope) {
    return this.axios.put(`acl/scope/${scope.id}`, {
      category_id: scope.category_id,
      name: scope.name,
      scope: scope.scope
    });
  }

  destroy(scope) {
    return this.axios.delete(`acl/scope/${scope.id}`);
  }
}
