<template>
<div v-if="!loading">
  <div class="text-right">
    <a href="#new-module" data-toggle="modal"
      class="btn btn-outline-danger m-btn m-btn--icon" v-if="$authorization.can('acl-module-store')">
      <i class="fa fa-plus"></i> Novo Módulo
    </a>
  </div>
  <u-table :columns="columns" v-if="$authorization.can('acl-module')">
    <tr v-if="$store.state.configuration.loading">
      <td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
        <div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
      </td>
    </tr>
    <tr v-else v-for="(module, moduleIndex) in modules" :key="moduleIndex">
      <td>{{ module.name }}</td>
      <td>{{ module.scopes.length }}</td>
      <td class="m-datatable__cell text-right">
        <a href="javascript:;" data-toggle="modal" title="deletar" class="ml-1 mr-4" data-target="#update-module" @click="setModuleIndex(moduleIndex)" v-if="$authorization.can('acl-module-update')">
        <i class="fa flaticon-settings-1"></i>
        </a>
        <a href="javascript:;" data-toggle="modal" title="deletar" @click="setModuleIndex(moduleIndex)" data-target="#module-destroy" v-if="$authorization.can('acl-module-delete')">
        <i class="fa flaticon-delete"></i>
        </a>
      </td>
    </tr>
  </u-table>
  <u-modal title="Novo Módulo" id="new-module" size="sm">
    <div slot="body">
      <label>Nome</label>
      <input type="text" @keyup.enter="btnSaveClick" class="form-control" v-model="name">
    </div>
    <div slot="footer">
      <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
      <button type="button" class="btn m-btn btn-primary" ref="btnSave" data-dismiss="modal" @click="store">Salvar</button>
    </div>
  </u-modal>
   <u-modal v-if="modules[moduleIndex]"  title="Atualizar Módulo" id="update-module" size="sm">
     <div slot="body">
        <label>Nome</label>
        <input type="text" @keyup.enter="btnUpdateClick" class="form-control" v-model="modules[moduleIndex].name">
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn m-btn btn-primary" data-dismiss="modal" ref="btnUpdate" @click="update">Salvar</button>
      </div>
   </u-modal>
    <u-modal title="Excluir Módulo" id="module-destroy" size="sm">
      <div slot="body">
        <div class="row">
          <p>Deseja realmente excluir esse módulo?</p>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn m-btn btn-primary" data-dismiss="modal" @click="destroy">Excluir</button>
      </div>
    </u-modal>
</div>
</template>

<script>
import UTable from '@/components/UTable';
import UModal from '@/components/UModal';
import ACLModuleService from '../../services/ACLModuleService';
export default {
  name: 'acl-module',
  components: {
    UTable,
    UModal,
  },
  data() {
    return {
      columns: [
        'Nome',
        'Regras',
        ' '
      ],
      name: ' ',
      ACLModuleService: new ACLModuleService(),
      modules: [],
      loading: true,
      module: {},
      moduleIndex: 0,
    }
  },
  created() {
    this.all();
  },
  methods: {
    all(){
      this.$store.dispatch('setLoadingACL', true);
      this.ACLModuleService.all()
      .then((response) => {
        this.$store.dispatch('setLoadingACL', false);
        this.modules = response.data.modules;
        this.loading = false
      })
      .catch((error) => {
        this.$store.dispatch('setLoadingACL', false);
        console.log(error);
      })
    },
    store() {
      this.ACLModuleService.store(this.name)
      .then((response) => {
        this.modules = response.data.modules;
        this.name = '';
        swal('Sucesso!', "Módulo cadastrado!", "success");
      })
      .catch((error) => {
        console.log(error);
        swal('Ops!', "O módulo não foi cadastrado!", "error");
      })
    },
    update() {
      this.ACLModuleService.update(this.modules[this.moduleIndex])
      .then(() => {
        swal('Sucesso!', "Módulo alterado!", "success");
      })
      .catch((error) => {
        console.log(error);
        swal('Ops!', "O módulo não foi alterado!", "error");
      })
    },
    destroy() {
      this.ACLModuleService.destroy(this.modules[this.moduleIndex])
      .then(() => {
        this.modules.splice(this.moduleIndex, 1);
        swal('Sucesso!', "Módulo excluido!", "success");
      })
      .catch((error) => {
        console.log(error);
        swal('Ops!', "O módulo não foi excluído!", "error");
      })
    },
    btnSaveClick() {
      this.$refs.btnSave.click();
    },
    btnUpdateClick() {
      this.$refs.btnUpdate.click();
    },
    setModule(module) {
      this.module = module;
    },
    setModuleIndex(index) {
      this.moduleIndex = index;
    }, 
  }
}
</script>
