/* global axios */
export default class ACLModuleService {
  constructor() {
    this.axios = axios;
  }

  all() {
    return this.axios.get('acl/modules');
  }

  store(name) {
    return this.axios.post('acl/module', {name})
  }

  update(module) {
    return this.axios.put(`acl/module/${module.id}`, {name: module.name})
  }

  destroy(module) {
    return this.axios.delete(`acl/module/${module.id}`)
  }
}
