<template>
  <div v-if="!loading" class="mt-5">
    <u-portlet v-show="$authorization.can('acl-group-update')" :title="title">
      <div class="form-group">
        <label for="group-name">Nome do Grupo</label>
        <input type="text" v-model="role.name" class="form-control">
      </div>

      <div v-if="role.users.length" class="border-bottom">
        <div class="row mt-5">
          <div class="col-12">
            <h5 class="m-section__heading">Usuários</h5>
          </div>
        </div>
        <u-table :columns="column">
          <tr v-for="(user, userIndex) in role.users" :key="userIndex">
            <td width="30%">{{ user.first_name }}</td>
            <td width="30%">{{ user.last_name }}</td>
            <td width="40%">{{ user.email }}</td>
          </tr>
        </u-table>
      </div>
      
      <div class="row mt-3 mb-3 pt-4">
        <div class="col-6">
          <h5 class="mb-3">Tabela de Privilégios</h5>
        </div>
      </div>

      <div v-if="scope_categories.length" v-for="(category, categoryIndex) in scope_categories" :key="categoryIndex" class="row mb-5 border-bottom">
        <div class="row col-12 mb-3" style="padding-left: 30px;">
          <div style="margin-top: -2px;">
            <span class="font-weight-bolder mr-2    m--regular-font-size-lg2">
              {{ category.name }}
            </span>
          </div> 
        </div>

          <div v-if="category.scopes.length" v-for="(scope, scopeIndex) in category.scopes" :key="scopeIndex"  class="row col-12 mb-3">
            <div class="row col-12" style="padding-left: 30px;">
              <label class="m-checkbox m-checkbox--bold m-checkbox--state-primary">
                <input type="checkbox" :value="scope.id" v-model="role.id_scopes" data-class=".rule-1" class="acl-rules acl-modules"> 
                <span></span>
                {{ scope.name }}
              </label>
            </div>
          </div>

        </div>

        <div class="row text-right"><div class="col-lg-12">
          <button type="submit" class="btn btn-brand" @click="update">Salvar</button>
          </div>
        </div>


    </u-portlet>
    <u-modal id="new-user" title="Novo usuário">
      <div class="body">
        <div class="form-group">
          <select class="form-control">
           <option>Opção</option> 
          </select>
        </div>
      </div>
    </u-modal>
     <u-modal title="Excluir Usuário do Grupo" id="delete-user">
      <div slot="body">
        <p>Deseja realmente excluir esse usuário do grupo?</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn m-btn btn-primary" data-dismiss="modal">Excluir</button>
      </div>
    </u-modal>
  </div>
</template>
<script>

import UPortlet from '@/components/UPortlet';
import UTable from '@/components/UTable';
import UModal from '@/components/UModal';
import ACLService from '../services/ACLService';
export default {
  components: {
    UPortlet,
    UTable,
    UModal
  },
  created() {
    this.get();
  },
  computed: {
    title() {
      return this.$route.params.id ? 'Atualizar' : 'Cadastrar';
    }
  },
  data() {
    return {
      column: [
        'Nome',
        'Sobrenome',
        'Email',
      ],
      aclService: new ACLService(),
      scope_categories: [],
      role: {},
      loading: true,
    }
  },
  methods: {
    get() {
      this.aclService.get(this.$route.params.id)
      .then((response) => {
        this.role = response.data.role;
        this.scope_categories = response.data.scope_categories;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
      })
    },
    update() {
      this.aclService.update(this.$route.params.id, this.role)
        .then((response) => {
          swal('Sucesso!', 'Grupo de permissões atualizado!', 'success');
        })
        .catch((error) => {
          swal('Ops!', 'Ocorreu um erro ao atualizar o grupo de permissões', 'error');
          console.log(error);
        });
    }
  },
}
</script>
