export const userStatus = (status) => {
  switch (status) {
    case 'active':
      return { status: 'Ativo', color: 'success' };
    case 'inactive':
      return { status: 'Inativo', color: 'danger' };
    case 'excluded':
      return { status: 'Excluído', color: 'danger' };
    default:
      return { status: 'Indefinido', color: 'secondary' };
  }
};
