/* global axios */
export default class UserService {
  constructor() {
    this.axios = axios;
  }

  all() {
    return this.axios.get('users');
  }

  update(user) {
    return this.axios.put(`user/${user.id}`, user);
  }

  destroy(user) {
    return this.axios.delete(`user/${user.id}`);
  }
}
