<template>
<div v-if="!loading">
  <div class="text-right">
    <a href="#new-scope" data-toggle="modal"
      class="btn btn-outline-danger m-btn m-btn--icon" v-if="$authorization.can('acl-scope-store')">
      <i class="fa fa-plus"></i> Novo Escopo
    </a>
  </div>
  <u-table :columns="columns">
    <tr v-if="$store.state.configuration.loading">
      <td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
        <div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
      </td>
    </tr>
    <tr v-else v-for="(scope, scopeIndex) in scopes" :key="scopeIndex">
      <td>{{ scope.name }}</td>
      <td>{{ scope.scope }}</td>
      <td>{{ scope.module.name }}</td>
      <td class="m-datatable__cell text-right">
        <a href="javascript:;" data-toggle="modal" title="deletar" class="ml-1 mr-4" data-target="#update-scope" @click="setScopeIndex(scopeIndex)" v-if="$authorization.can('acl-scope-update')">
        <i class="fa flaticon-settings-1"></i>
        </a>
        <a href="javascript:;" data-toggle="modal" title="deletar" @click="setScopeIndex(scopeIndex)" data-target="#scope-destroy" v-if="$authorization.can('acl-scope-delete')">
        <i class="fa flaticon-delete"></i>
        </a>
      </td>
    </tr>
  </u-table>
  <u-modal v-if="scopes[scopeIndex]"  title="Novo Escopo" id="new-scope" size="sm">
    <div slot="body">
      <div class="form-group">
      <label>Módulo</label>
      <select class="form-control" v-model="scope.category_id">
        <option v-for="(module, moduleIndex) in scopeModules" :key="moduleIndex" :value="module.id">{{ module.name }}</option> 
      </select>
      </div>
      
      <div class="form-group">
        <label>Nome</label>
        <input type="text" @keyup.enter="btnSaveClick" class="form-control" v-model="scope.name">
      </div>

      <div class="form-group">
        <label>Slug</label>
        <input type="text" @keyup.enter="btnSaveClick" class="form-control" v-model="scope.scope">
      </div>
    </div>
    <div slot="footer">
      <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
      <button type="button" class="btn m-btn btn-primary" ref="btnSave" data-dismiss="modal" @click="store">Salvar</button>
    </div>
  </u-modal>
   <u-modal v-if="scopes[scopeIndex]" title="Atualizar Escopo" id="update-scope" size="sm">
     <div slot="body">
       <div class="form-group">
        <label>Módulo</label>
        <select class="form-control" v-model="scopes[scopeIndex].category_id">
          <option v-for="(module, moduleIndex) in scopeModules" :key="moduleIndex" :value="module.id">{{ module.name }}</option> 
        </select>
       </div>
        
        <div class="form-group">
          <label>Nome</label>
          <input type="text" @keyup.enter="btnUpdateClick" class="form-control" v-model="scopes[scopeIndex].name">
        </div>

        <div class="form-group">
          <label>Slug</label>
          <input type="text" @keyup.enter="btnUpdateClick"  class="form-control" v-model="scopes[scopeIndex].scope">
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn m-btn btn-primary" data-dismiss="modal" ref="btnUpdate" @click="update">Salvar</button>
      </div>
   </u-modal>
    <u-modal title="Excluir Escopo" id="scope-destroy" size="sm">
      <div slot="body">
        <p>Deseja realmente excluir esse escopo?</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn m-btn btn-primary" data-dismiss="modal" @click="destroy">Excluir</button>
      </div>
    </u-modal>
</div>
</template>

<script>
import UTable from '@/components/UTable';
import UModal from '@/components/UModal';
import ACLScopeService from '../../services/ACLScopeService';
export default {
  name: 'acl-scope',
  components: {
    UTable,
    UModal,
  },
  data() {
    return {
      columns: [
        'Nome',
        'Slug',
        'Módulo',
        '',
      ],
      ACLScopeService: new ACLScopeService(),
      scopes: [],
      loading: true,
      scope: {},
      scopeIndex: 0,
      scopeModules: [],
    }
  },
  created() {
    this.all();
  },
  methods: {
    all(){
      this.$store.dispatch('setLoadingACL', true);
      this.ACLScopeService.all()
      .then((response) => {
        this.$store.dispatch('setLoadingACL', false);
        this.scopes = response.data.scopes;
        this.scopeModules = response.data.modules;
        this.loading = false;
      })
      .catch((error) => {
        this.$store.dispatch('setLoadingACL', false);
        console.log(error);
      })
    },
    store() {
      this.ACLScopeService.store(this.scope)
      .then((response) => {
        this.scopes = response.data.scopes;
        swal('Sucesso!', "Escopo cadastrado!", "success");
        this.scope = {};
      })
      .catch((error) => {
        console.log(error);
        swal('Ops!', "O escopo não foi cadastrado!", "error");
      })
    },
    update() {
      this.ACLScopeService.update(this.scopes[this.scopeIndex])
      .then(() => {
        swal('Sucesso!', "Escopo alterado!", "success");
      })
      .catch((error) => {
        console.log(error);
        swal('Ops!', "O escopo não foi alterado!", "error");
      })
    },
    destroy() {
      this.ACLScopeService.destroy(this.scopes[this.scopeIndex])
      .then(() => {
        this.scopes.splice(this.scopeIndex, 1);
        swal('Sucesso!', "Escopo excluido!", "success");
      })
      .catch((error) => {
        console.log(error);
        swal('Ops!', "O escopo não foi excluído!", "error");
      })
    },
    btnSaveClick() {
      this.$refs.btnSave.click();
    },
    btnUpdateClick() {
      this.$refs.btnUpdate.click();
    },
    setScope(scope) {
      this.scope = scope;
    },
    setScopeIndex(index) {
      this.scopeIndex = index;
    }, 
  }
}
</script>
