<template>
  <div class="mt-5">
    <div class="row">
      <side-menu></side-menu>
      <div class="col-xl-9 col-lg-8">
        <div class="m-portlet m-portlet--tabs">
          <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                  Regras e Permissões
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools">
              <ul class="nav nav-tabs m-tabs-line m-tabs-line--right" role="tablist">
                <li class="nav-item m-tabs__item">
                  <a class="nav-link m-tabs__link active" data-toggle="tab" href="#m_portlet_base_demo_1_tab_content" role="tab" v-if="$authorization.can('acl-module')">
                    <i class="flaticon-network"></i> Grupos
                  </a>
                </li>
                <li class="nav-item m-tabs__item">
                  <a class="nav-link m-tabs__link" data-toggle="tab" href="#m_portlet_base_demo_2_tab_content" role="tab" v-if="$authorization.can('acl-scopes')">
                    <i class="flaticon-web"></i> Escopos
                  </a>
                </li>
                <li class="nav-item m-tabs__item">
                  <a class="nav-link m-tabs__link" data-toggle="tab" href="#m_portlet_base_demo_3_tab_content" role="tab" v-if="$authorization.can('acl-group-store')">
                    <i class="flaticon-map"></i> Módulos
                  </a>
                </li>
              </ul>
            </div>

          </div>
          <div class="m-portlet__body">
            <div class="tab-content">
              <div class="tab-pane active" id="m_portlet_base_demo_1_tab_content" role="tabpanel">
                <acl-groups></acl-groups>
              </div>
              <div class="tab-pane" id="m_portlet_base_demo_2_tab_content" role="tabpanel">
                <acl-scopes></acl-scopes>
              </div>
              <div class="tab-pane" id="m_portlet_base_demo_3_tab_content" role="tabpanel">
                <acl-modules></acl-modules>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UTable from '@/components/UTable';
  import UModal from '@/components/UModal';
  import AclModules from '../components/acl/AclModules';
  import AclGroups from '../components/acl/AclGroups';
  import AclScopes from '../components/acl/AclScopes';
  import SideMenu from "../components/SideMenu";

  export default {
    components: {
      UTable,
      UModal,
      AclModules,
      AclGroups,
      AclScopes,
      SideMenu
    },
  }
</script>