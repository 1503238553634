<template>
  <div>
    <div class="text-right">
      <a href="#new-group" data-toggle="modal" class="btn btn-outline-danger m-btn m-btn--icon" v-if="$authorization.can('acl-group-store')">
        <i class="fa fa-plus"></i> Novo Grupo
      </a>
    </div>
		<u-table :columns="columns" v-if="$authorization.can('acl-group-store')">
      <tr v-if="$store.state.configuration.loading">
        <td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
          <div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
        </td>
      </tr>
      <tr v-else v-for="(role, roleIndex) in roles" :key="roleIndex">
        <td>{{ role.name }}</td>
        <td>{{ role.users.length }}</td>
        <td>{{ role.count_modules }}</td>
        <td>{{ role.scopes.length }}</td>
        <td class="m-datatable__cell text-right">
          <a href="javascript:;" @click="() => $router.push({name: 'acl.edit', params: { id: role.id }})" class="ml-1 mr-4" v-if="$authorization.can('acl-group-update')
            ">
          <i class="fa flaticon-settings-1"></i>
          </a>
          <a href="javascript:;" data-toggle="modal" data-target="#remove-group" title="deletar"  @click="() => {roleDestroy = role; roleDestroyIndex = roleIndex}" v-if="$authorization.can('acl-group-delete')">
          <i class="fa flaticon-delete"></i>
          </a>
        </td>
      </tr>
    </u-table>
    <u-modal title="Novo Grupo" id="new-group" size="md">
     <div slot="body">
        <label for="Nome">Nome</label>
        <input type="text" @keyup.enter="btnSave" v-model="name" class="form-control">
      </div>
      <div slot="footer">
        <button type="button" class="btn mr-2 m-btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" ref="btnSave" class="btn btn-primary" data-dismiss="modal" @click="save">Salvar</button>
      </div>
   </u-modal>

   <u-modal title="Excluir Grupo" id="remove-group" size="md">
     <div slot="body">
        <p>Deseja realmente excluir este grupo?</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn mr-2 m-btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" ref="btnSave" class="btn btn-primary" data-dismiss="modal" @click="destroy">Salvar</button>
      </div>
   </u-modal>
  </div>
</template>

<script>
  import UTable from '@/components/UTable';
  import UModal from '@/components/UModal';
  import ACLSService from '../../services/ACLService';

export default {
  components: {
    UTable,
    UModal
  },
  data() {
    return {
      name: '',
      columns: [
        'Nome',
        'Usuários',
        'Módulos',
        'Escopos',
        ' '
      ],
      roles: [],
      aclService: new ACLSService(),
      roleDestroy: {},
      roleDestroyIndex: 0,
    }
  },
  created() {
    this.all();
  },
  methods: {
    all() {
      this.$store.dispatch('setLoadingACL', true);
      this.aclService.all()
        .then((response) => {
          this.roles = response.data.roles;
          this.$store.dispatch('setLoadingACL', false);
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch('setLoadingACL', false);
        })
    },
    save() {
      this.aclService.store(this.name)
        .then((response) => {
          swal('Sucesso!', 'Grupo de permmissões cadastrado', 'success');
        })
        .catch((error) => {
          swal('Sucesso!', 'Ocorreu um erro ao cadastrar o grupo', 'error');
          console.log(error);
        })
    },
    destroy() {
      this.aclService.destroy(this.roleDestroy.id)
        .then(() => {
          swal('Sucesso!', 'Grupo de permissões excluído', 'success');
          this.roles.splice(this.roleDestroyIndex, 1);
        })
        .catch((error) => {
          swal('Ops!', 'Ocorreu um erro ao excluir o grupo', 'error');
          console.log(error);
        })
    },
    btnSave() {
      this.$refs.btnSave.click();
    },
  },
}
</script>