/* global axios */
export default class ACLSService {
  constructor() {
    this.axios = axios;
  }

  all() {
    return this.axios.get('acl/roles');
  }

  get(roleId) {
    return this.axios.get(`acl/role/${roleId}`);
  }

  store(name) {
    return this.axios.post('acl/role', { name });
  }

  update(id, role) {
    return this.axios.put(`acl/role/${id}`, role);
  }

  destroy(id) {
    return this.axios.delete(`acl/role/${id}`);
  }
}
