<template>
  <div class="mt-5">
    <div class="row">
      <side-menu></side-menu>
      <div class="col-xl-9 col-lg-8">
        <div class="m-portlet m-portlet--tabs">
          <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
              <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                  Usuários
                </h3>
              </div>
            </div>
            <div class="m-portlet__head-tools pt-3">
              <input class="form-control m-input" v-model="search" @keyup="filterUsers" placeholder="Pesquisar...">
            </div>
          </div>
          <div class="m-portlet__body">

            <u-table :columns="columns">
              <tr v-if="loading">
                <td colspan="9" class="text-center pt-4 pb-4 m-auto" align="center">
                  <div class="m-loader m-loader--danger" style="width: 30px; display: inline-block;"></div>
                </td>
              </tr>
              <tr v-else v-for="(user, userIndex) in usersFilter" :key="userIndex">
                <td>{{ `${user.first_name} ${user.last_name}` }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <span :class="`m-badge m-badge--${ userStatus(user.status).color } m-badge--wide`">
                      {{ userStatus(user.status).status }}
                  </span></td>
                <td class="m-datatable__cell text-right">
                  <a href="javascript:;" data-toggle="modal" class="ml-1 mr-4" data-target="#remove-user" title="deletar" @click="() => {userSelected = user; userSelectedIndex = userIndex}" v-if="!user.deleted_at && $authorization.can('user-delete')">
                  <i class="fa flaticon-delete"></i>
                  </a>
                  <a href="javascript:;" data-toggle="modal" data-target="#update-user" @click="editItem(user)" v-if="$authorization.can('user-update')">
                  <i class="fa flaticon-settings-1"></i>
                  </a>
                </td>
              </tr>
            </u-table>
          </div>
        </div>
      </div>
    </div>

    <u-modal v-if="!loading && users.length" title="Alterar Usuário" id="update-user" size="lg">
     <div slot="body">
        <div class="row">
          <div class="col-6">
            <div class="form-group m-form__group">
              <label>Nome</label>
              <input type="text" v-model="users[userSelectedIndex].first_name" class="form-control m-input m-input--square">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group m-form__group">
              <label>Sobrenome</label>
              <input type="text" v-model="users[userSelectedIndex].last_name" class="form-control m-input m-input--square">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group m-form__group">
              <label for="exampleInputEmail1">Email</label>
              <input type="email" v-model="users[userSelectedIndex].email" class="form-control m-input m-input--square">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group m-form__group">
              <label>Telefone</label>
              <the-mask class="form-control m-input m-input--square" v-model="users[userSelectedIndex].phone" :mask="['(##) ####-####', '(##) #####-####']" />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6">
            <label>Perfil</label>
            <select class="form-control m-input m-input--square" v-model="users[userSelectedIndex].profile_id">
              <option
                v-if="profiles && profiles.length"
                v-for="(profile, profileIndex) in profiles"
                :key="profileIndex"
                :value="profile.id"
              >
                {{ profile.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6">
            <label>Status</label>
            <select class="form-control m-input m-input--square" v-model="users[userSelectedIndex].status">
              <option value="active">Ativo</option>
              <option value="inactive">Inativo</option>
              <option value="excluded">Excluído</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <label>Empresa</label>
            <select class="form-control m-input m-input--square" v-model="users[userSelectedIndex].company_id">
              <option
                v-if="companies && companies.length"
                v-for="(company, companyIndex) in companies"
                :key="companyIndex"
                :value="company.id"
              >
                {{ company.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-6">
            <label>Grupo de permissões </label>
            <select class="form-control m-input m-input--square" v-model="users[userSelectedIndex].roles[0].id">
              <option
                v-if="roles && roles.length"
                v-for="(role, roleIndex) in roles"
                :key="roleIndex"
                :value="role.id"
              >
                {{ role.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn mr-2 m-btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" ref="btnSave" class="btn btn-primary" data-dismiss="modal" @click="update">Atualizar</button>
      </div>
   </u-modal>

    <u-modal title="Excluir Usuário" id="remove-user" size="md">
     <div slot="body">
        <p>Deseja realmente excluir este usuário?</p>
      </div>
      <div slot="footer">
        <button type="button" class="btn mr-2 m-btn btn-secondary" data-dismiss="modal">Cancelar</button>
        <button type="button" ref="btnSave" class="btn btn-primary" data-dismiss="modal" @click="destroy">Excluir</button>
      </div>
   </u-modal>
  </div>
</template>

<script>
  import UTable from '@/components/UTable';
  import UModal from '@/components/UModal';
  import AclGroups from '../components/acl/AclGroups';
  import SideMenu from "../components/SideMenu";
  import UserService from '../services/UserService';
  import Switches from 'vue-switches';
  import { userStatus } from '../support';

  export default {
    components: {
      UTable,
      UModal,
      AclGroups,
      SideMenu,
      Switches,
    },
    data() {
      return {
        userService: new UserService(),
        users: [],
        usersFilter: [],
        userSelected: {},
        userSelectedIndex: 0,
        profiles: [],
        companies: [],
        roles: [],
        columns: [
          'Nome',
          'Email',
          'Status',
          ' '
        ],
        loading: true,
        search: '',
      }
    },
    created() {
      this.getUsers();
    },
    methods: {
      getUsers() {
        this.userService.all()
          .then((response) => {
            this.users = response.data.users;
            this.usersFilter = response.data.users;
            this.profiles = response.data.profiles;
            this.companies = response.data.companies;
            this.roles = response.data.roles;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      },
      editItem (item) {
        this.userSelectedIndex = this.users.indexOf(item);
        this.userSelected = Object.assign({}, item);
      },
      update() {
        this.userService.update(this.users[this.userSelectedIndex])
          .then(() => {
            if (this.users[this.userSelectedIndex].status === 'active') {
              this.users[this.userSelectedIndex].deleted_at = null;
            }
            swal('Sucesso!', 'Usuário atualizado', 'success');
          })
          .catch((error) => {
            console.log(error);
            swal('Ops!', 'Ocorreu um erro ao atualizar o usuário', 'error');
          })
      },
      destroy() {
        this.userService.destroy(this.userSelected)
          .then((response) => {
            this.users[this.userSelectedIndex].status = 'excluded';
            this.users[this.userSelectedIndex].deleted_at = true;
            swal('Sucesso!', 'Usuário excluído', 'success');
          })
          .catch((error) => {
            swal('Ops!', 'Ocorreu um erro ao excluir o usuário', 'error');
            console.log(error);
          })
      },
      filterUsers() {
        const exp = new RegExp(this.search.trim(), 'i');
        this.usersFilter = this.users.filter((user) => {
          let status = '';

          switch(user.status) {
            case 'active':
              status = 'ativo';
            break;
            case 'inactive':
              status = 'inativo';
            break;
            case 'excluded':
              status = 'excluído';
            break;
          }

          return exp.test(`${user.first_name} ${user.last_name}`) || exp.test(user.email) || exp.test(status);
        });
      },
      userStatus: userStatus,
    },
  }
</script>